:root {
  --background: white;
  --text-primary: black;
  --text-secondary: red;
  --accent: purple;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: yellow;
  --accent: darkred;
}

#stepsTable {
  color: var(--text-primary);
}

.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  /* padding: 20px; */
  height: 1000px;
  transition: all 0.5s;
}

.questionText {
  color: var(--text-primary);
  background-color: var(--background);
  border: var(--text-primary);
}

button {
  color: var(--text-primary);
  background-color: var(--background);
  border: 2px var(--text-primary) solid;
  float: right;
  transition: all 0.5s;
}

th {
  color: var(--text-primary);
  background-color: var(--background);
}

.bright {
  color: var(--text-secondary);
}

span {
  color: var(--text-primary);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#toast {
  color: var(--text-primary);
}

.button {
  border-radius: 5px;
  text-align: center;
  height: 50px;
  line-height: 45px;
  width: 50px;
  text-decoration: none;
  display: inline-block;
  color: rgba(255, 255, 255, 0.4);
  background: #282e3f;
  border: 1px solid #333947;
  margin: 20px;
}

.active {
  color: #fff;
  background-color: var(--background);
  border: 1px solid var(--background);
}

.pushed {
  position: relative;
  box-shadow: 0 0 0 0 var(--background);
  cursor: pointer;
  background: var(--background);
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(90, 0, 141, 0);
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1), 0 0 0 0 rgba(51, 51, 51, 1);
  }
  to {
    box-shadow: 0 0 0 0.65em rgba(255, 255, 255, 1),
      0 0 0 1em rgba(51, 51, 51, 0);
  }
}

div[class^="pulse-multiple"] {
  background: var(--background);
  border-radius: 50%;
  color: var(--text-primary);
  cursor: pointer;
  display: inline-block;
  height: 100px;
  line-height: 50px;
  /* margin-top: 100px; */
  padding: 1.4em;
  text-align: center;
  width: 100px;
}

.pulse-multiple:active {
  animation: pulse 0.6s infinite ease-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  background: var(--background);
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}
